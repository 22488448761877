/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Invitation } from './Invitation';
import {
    InvitationFromJSON,
    InvitationFromJSONTyped,
    InvitationToJSON,
} from './Invitation';
import type { Membership } from './Membership';
import {
    MembershipFromJSON,
    MembershipFromJSONTyped,
    MembershipToJSON,
} from './Membership';
import type { PaymentMethod } from './PaymentMethod';
import {
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';
import type { Subscription } from './Subscription';
import {
    SubscriptionFromJSON,
    SubscriptionFromJSONTyped,
    SubscriptionToJSON,
} from './Subscription';

/**
 * 
 * @export
 * @interface PatchedTeam
 */
export interface PatchedTeam {
    /**
     * 
     * @type {number}
     * @memberof PatchedTeam
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    slug?: string;
    /**
     * 
     * @type {Array<Membership>}
     * @memberof PatchedTeam
     */
    readonly members?: Array<Membership>;
    /**
     * 
     * @type {Array<Invitation>}
     * @memberof PatchedTeam
     */
    readonly invitations?: Array<Invitation>;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    readonly dashboardUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedTeam
     */
    readonly isAdmin?: boolean;
    /**
     * 
     * @type {Subscription}
     * @memberof PatchedTeam
     */
    readonly subscription?: Subscription | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedTeam
     */
    readonly hasActiveSubscription?: boolean;
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof PatchedTeam
     */
    readonly paymentMethods?: Array<PaymentMethod> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeam
     */
    companyUuid?: string | null;
}

/**
 * Check if a given object implements the PatchedTeam interface.
 */
export function instanceOfPatchedTeam(value: object): value is PatchedTeam {
    return true;
}

export function PatchedTeamFromJSON(json: any): PatchedTeam {
    return PatchedTeamFromJSONTyped(json, false);
}

export function PatchedTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTeam {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'slug': json['slug'] == null ? undefined : json['slug'],
        'members': json['members'] == null ? undefined : ((json['members'] as Array<any>).map(MembershipFromJSON)),
        'invitations': json['invitations'] == null ? undefined : ((json['invitations'] as Array<any>).map(InvitationFromJSON)),
        'dashboardUrl': json['dashboard_url'] == null ? undefined : json['dashboard_url'],
        'isAdmin': json['is_admin'] == null ? undefined : json['is_admin'],
        'subscription': json['subscription'] == null ? undefined : SubscriptionFromJSON(json['subscription']),
        'hasActiveSubscription': json['has_active_subscription'] == null ? undefined : json['has_active_subscription'],
        'paymentMethods': json['payment_methods'] == null ? undefined : ((json['payment_methods'] as Array<any>).map(PaymentMethodFromJSON)),
        'companyUuid': json['company_uuid'] == null ? undefined : json['company_uuid'],
    };
}

export function PatchedTeamToJSON(value?: Omit<PatchedTeam, 'id'|'members'|'invitations'|'dashboard_url'|'is_admin'|'subscription'|'has_active_subscription'|'payment_methods'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'slug': value['slug'],
        'company_uuid': value['companyUuid'],
    };
}

