/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Invitation,
  PaginatedInvitationList,
  PaginatedTeamList,
  PatchedInvitation,
  PatchedTeam,
  Team,
} from '../models/index';
import {
    InvitationFromJSON,
    InvitationToJSON,
    PaginatedInvitationListFromJSON,
    PaginatedInvitationListToJSON,
    PaginatedTeamListFromJSON,
    PaginatedTeamListToJSON,
    PatchedInvitationFromJSON,
    PatchedInvitationToJSON,
    PatchedTeamFromJSON,
    PatchedTeamToJSON,
    TeamFromJSON,
    TeamToJSON,
} from '../models/index';

export interface InvitationsCreateRequest {
    teamSlug: string;
    invitation: Omit<Invitation, 'id'|'invited_by'>;
}

export interface InvitationsDestroyRequest {
    id: string;
    teamSlug: string;
}

export interface InvitationsListRequest {
    teamSlug: string;
    page?: number;
}

export interface InvitationsPartialUpdateRequest {
    id: string;
    teamSlug: string;
    patchedInvitation?: Omit<PatchedInvitation, 'id'|'invited_by'>;
}

export interface InvitationsRetrieveRequest {
    id: string;
    teamSlug: string;
}

export interface InvitationsUpdateRequest {
    id: string;
    teamSlug: string;
    invitation: Omit<Invitation, 'id'|'invited_by'>;
}

export interface TeamsCreateRequest {
    team: Omit<Team, 'id'|'members'|'invitations'|'dashboard_url'|'is_admin'|'subscription'|'has_active_subscription'|'payment_methods'>;
}

export interface TeamsDestroyRequest {
    id: number;
}

export interface TeamsGetBySlugRequest {
    teamSlug: string;
}

export interface TeamsListRequest {
    page?: number;
}

export interface TeamsPartialUpdateRequest {
    id: number;
    patchedTeam?: Omit<PatchedTeam, 'id'|'members'|'invitations'|'dashboard_url'|'is_admin'|'subscription'|'has_active_subscription'|'payment_methods'>;
}

export interface TeamsRetrieveRequest {
    id: number;
}

export interface TeamsUpdateRequest {
    id: number;
    team: Omit<Team, 'id'|'members'|'invitations'|'dashboard_url'|'is_admin'|'subscription'|'has_active_subscription'|'payment_methods'>;
}

/**
 * TeamsApi - interface
 * 
 * @export
 * @interface TeamsApiInterface
 */
export interface TeamsApiInterface {
    /**
     * 
     * @param {string} teamSlug 
     * @param {Invitation} invitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    invitationsCreateRaw(requestParameters: InvitationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>>;

    /**
     */
    invitationsCreate(requestParameters: InvitationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation>;

    /**
     * 
     * @param {string} id A UUID string identifying this invitation.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    invitationsDestroyRaw(requestParameters: InvitationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    invitationsDestroy(requestParameters: InvitationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} teamSlug 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    invitationsListRaw(requestParameters: InvitationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInvitationList>>;

    /**
     */
    invitationsList(requestParameters: InvitationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInvitationList>;

    /**
     * 
     * @param {string} id A UUID string identifying this invitation.
     * @param {string} teamSlug 
     * @param {PatchedInvitation} [patchedInvitation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    invitationsPartialUpdateRaw(requestParameters: InvitationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>>;

    /**
     */
    invitationsPartialUpdate(requestParameters: InvitationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation>;

    /**
     * 
     * @param {string} id A UUID string identifying this invitation.
     * @param {string} teamSlug 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    invitationsRetrieveRaw(requestParameters: InvitationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>>;

    /**
     */
    invitationsRetrieve(requestParameters: InvitationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation>;

    /**
     * 
     * @param {string} id A UUID string identifying this invitation.
     * @param {string} teamSlug 
     * @param {Invitation} invitation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    invitationsUpdateRaw(requestParameters: InvitationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>>;

    /**
     */
    invitationsUpdate(requestParameters: InvitationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation>;

    /**
     * 
     * @param {Team} team 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsCreateRaw(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>>;

    /**
     */
    teamsCreate(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team>;

    /**
     * 
     * @param {number} id A unique integer value identifying this team.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsDestroyRaw(requestParameters: TeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    teamsDestroy(requestParameters: TeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {string} teamSlug The slug of the team to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsGetBySlugRaw(requestParameters: TeamsGetBySlugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>>;

    /**
     */
    teamsGetBySlug(requestParameters: TeamsGetBySlugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team>;

    /**
     * 
     * @param {number} [page] A page number within the paginated result set.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsListRaw(requestParameters: TeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTeamList>>;

    /**
     */
    teamsList(requestParameters: TeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTeamList>;

    /**
     * 
     * @param {number} id A unique integer value identifying this team.
     * @param {PatchedTeam} [patchedTeam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsPartialUpdateRaw(requestParameters: TeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>>;

    /**
     */
    teamsPartialUpdate(requestParameters: TeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team>;

    /**
     * 
     * @param {number} id A unique integer value identifying this team.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsRetrieveRaw(requestParameters: TeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>>;

    /**
     */
    teamsRetrieve(requestParameters: TeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team>;

    /**
     * 
     * @param {number} id A unique integer value identifying this team.
     * @param {Team} team 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApiInterface
     */
    teamsUpdateRaw(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>>;

    /**
     */
    teamsUpdate(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team>;

}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI implements TeamsApiInterface {

    /**
     */
    async invitationsCreateRaw(requestParameters: InvitationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling invitationsCreate().'
            );
        }

        if (requestParameters['invitation'] == null) {
            throw new runtime.RequiredError(
                'invitation',
                'Required parameter "invitation" was null or undefined when calling invitationsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/team/api/invitations/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationToJSON(requestParameters['invitation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     */
    async invitationsCreate(requestParameters: InvitationsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation> {
        const response = await this.invitationsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invitationsDestroyRaw(requestParameters: InvitationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling invitationsDestroy().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling invitationsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/team/api/invitations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async invitationsDestroy(requestParameters: InvitationsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.invitationsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async invitationsListRaw(requestParameters: InvitationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedInvitationList>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling invitationsList().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/team/api/invitations/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedInvitationListFromJSON(jsonValue));
    }

    /**
     */
    async invitationsList(requestParameters: InvitationsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedInvitationList> {
        const response = await this.invitationsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invitationsPartialUpdateRaw(requestParameters: InvitationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling invitationsPartialUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling invitationsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/team/api/invitations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedInvitationToJSON(requestParameters['patchedInvitation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     */
    async invitationsPartialUpdate(requestParameters: InvitationsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation> {
        const response = await this.invitationsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invitationsRetrieveRaw(requestParameters: InvitationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling invitationsRetrieve().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling invitationsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/team/api/invitations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     */
    async invitationsRetrieve(requestParameters: InvitationsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation> {
        const response = await this.invitationsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async invitationsUpdateRaw(requestParameters: InvitationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Invitation>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling invitationsUpdate().'
            );
        }

        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling invitationsUpdate().'
            );
        }

        if (requestParameters['invitation'] == null) {
            throw new runtime.RequiredError(
                'invitation',
                'Required parameter "invitation" was null or undefined when calling invitationsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/a/{team_slug}/team/api/invitations/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InvitationToJSON(requestParameters['invitation']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitationFromJSON(jsonValue));
    }

    /**
     */
    async invitationsUpdate(requestParameters: InvitationsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Invitation> {
        const response = await this.invitationsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamsCreateRaw(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['team'] == null) {
            throw new runtime.RequiredError(
                'team',
                'Required parameter "team" was null or undefined when calling teamsCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamToJSON(requestParameters['team']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async teamsCreate(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.teamsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamsDestroyRaw(requestParameters: TeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling teamsDestroy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async teamsDestroy(requestParameters: TeamsDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.teamsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async teamsGetBySlugRaw(requestParameters: TeamsGetBySlugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['teamSlug'] == null) {
            throw new runtime.RequiredError(
                'teamSlug',
                'Required parameter "teamSlug" was null or undefined when calling teamsGetBySlug().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/slug/{team_slug}/`.replace(`{${"team_slug"}}`, encodeURIComponent(String(requestParameters['teamSlug']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async teamsGetBySlug(requestParameters: TeamsGetBySlugRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.teamsGetBySlugRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamsListRaw(requestParameters: TeamsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedTeamList>> {
        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedTeamListFromJSON(jsonValue));
    }

    /**
     */
    async teamsList(requestParameters: TeamsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedTeamList> {
        const response = await this.teamsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamsPartialUpdateRaw(requestParameters: TeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling teamsPartialUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTeamToJSON(requestParameters['patchedTeam']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async teamsPartialUpdate(requestParameters: TeamsPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.teamsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamsRetrieveRaw(requestParameters: TeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling teamsRetrieve().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async teamsRetrieve(requestParameters: TeamsRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.teamsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async teamsUpdateRaw(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling teamsUpdate().'
            );
        }

        if (requestParameters['team'] == null) {
            throw new runtime.RequiredError(
                'team',
                'Required parameter "team" was null or undefined when calling teamsUpdate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // ApiKeyAuth authentication
        }

        const response = await this.request({
            path: `/teams/api/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamToJSON(requestParameters['team']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async teamsUpdate(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.teamsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
