/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardChecks
 */
export interface CardChecks {
    /**
     * 
     * @type {string}
     * @memberof CardChecks
     */
    cvcCheck: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardChecks
     */
    addressLine1Check: string | null;
    /**
     * 
     * @type {string}
     * @memberof CardChecks
     */
    addressPostalCodeCheck: string | null;
}

/**
 * Check if a given object implements the CardChecks interface.
 */
export function instanceOfCardChecks(value: object): value is CardChecks {
    if (!('cvcCheck' in value) || value['cvcCheck'] === undefined) return false;
    if (!('addressLine1Check' in value) || value['addressLine1Check'] === undefined) return false;
    if (!('addressPostalCodeCheck' in value) || value['addressPostalCodeCheck'] === undefined) return false;
    return true;
}

export function CardChecksFromJSON(json: any): CardChecks {
    return CardChecksFromJSONTyped(json, false);
}

export function CardChecksFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardChecks {
    if (json == null) {
        return json;
    }
    return {
        
        'cvcCheck': json['cvc_check'],
        'addressLine1Check': json['address_line1_check'],
        'addressPostalCodeCheck': json['address_postal_code_check'],
    };
}

export function CardChecksToJSON(value?: CardChecks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cvc_check': value['cvcCheck'],
        'address_line1_check': value['addressLine1Check'],
        'address_postal_code_check': value['addressPostalCodeCheck'],
    };
}

