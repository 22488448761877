/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardWallet
 */
export interface CardWallet {
    /**
     * 
     * @type {string}
     * @memberof CardWallet
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CardWallet
     */
    dynamicLast4: string | null;
}

/**
 * Check if a given object implements the CardWallet interface.
 */
export function instanceOfCardWallet(value: object): value is CardWallet {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('dynamicLast4' in value) || value['dynamicLast4'] === undefined) return false;
    return true;
}

export function CardWalletFromJSON(json: any): CardWallet {
    return CardWalletFromJSONTyped(json, false);
}

export function CardWalletFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardWallet {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'dynamicLast4': json['dynamic_last4'],
    };
}

export function CardWalletToJSON(value?: CardWallet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': value['type'],
        'dynamic_last4': value['dynamicLast4'],
    };
}

