/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `acss_debit` - ACSS Dbit
 * * `affirm` - Affirm
 * * `afterpay_clearpay` - Afterpay Clearpay
 * * `alipay` - Alipay
 * * `au_becs_debit` - BECS Debit (Australia)
 * * `bacs_debit` - Bacs Direct Debit
 * * `bancontact` - Bancontact
 * * `blik` - BLIK
 * * `boleto` - Boleto
 * * `card` - Card
 * * `card_present` - Card present
 * * `cashapp` - Cash App
 * * `customer_balance` - Customer Balance
 * * `eps` - EPS
 * * `fpx` - FPX
 * * `giropay` - Giropay
 * * `grabpay` - Grabpay
 * * `ideal` - iDEAL
 * * `interac_present` - Interac (card present)
 * * `klarna` - Klarna
 * * `konbini` - Konbini
 * * `link` - Link
 * * `oxxo` - OXXO
 * * `p24` - Przelewy24
 * * `paynow` - PayNow
 * * `paypal` - PayPal
 * * `pix` - Pix
 * * `promptpay` - PromptPay
 * * `sepa_debit` - SEPA Direct Debit
 * * `sofort` - SOFORT
 * * `us_bank_account` - ACH Direct Debit
 * * `wechat_pay` - Wechat Pay
 * * `zip` - Zip
 * @export
 * @enum {string}
 */
export enum TypeEnum {
    AcssDebit = 'acss_debit',
    Affirm = 'affirm',
    AfterpayClearpay = 'afterpay_clearpay',
    Alipay = 'alipay',
    AuBecsDebit = 'au_becs_debit',
    BacsDebit = 'bacs_debit',
    Bancontact = 'bancontact',
    Blik = 'blik',
    Boleto = 'boleto',
    Card = 'card',
    CardPresent = 'card_present',
    Cashapp = 'cashapp',
    CustomerBalance = 'customer_balance',
    Eps = 'eps',
    Fpx = 'fpx',
    Giropay = 'giropay',
    Grabpay = 'grabpay',
    Ideal = 'ideal',
    InteracPresent = 'interac_present',
    Klarna = 'klarna',
    Konbini = 'konbini',
    Link = 'link',
    Oxxo = 'oxxo',
    P24 = 'p24',
    Paynow = 'paynow',
    Paypal = 'paypal',
    Pix = 'pix',
    Promptpay = 'promptpay',
    SepaDebit = 'sepa_debit',
    Sofort = 'sofort',
    UsBankAccount = 'us_bank_account',
    WechatPay = 'wechat_pay',
    Zip = 'zip'
}


export function instanceOfTypeEnum(value: any): boolean {
    for (const key in TypeEnum) {
        if (Object.prototype.hasOwnProperty.call(TypeEnum, key)) {
            if (TypeEnum[key as keyof typeof TypeEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TypeEnumFromJSON(json: any): TypeEnum {
    return TypeEnumFromJSONTyped(json, false);
}

export function TypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TypeEnum {
    return json as TypeEnum;
}

export function TypeEnumToJSON(value?: TypeEnum | null): any {
    return value as any;
}

