/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    line1: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    line2: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state: string | null;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postalCode: string | null;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): value is Address {
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('line1' in value) || value['line1'] === undefined) return false;
    if (!('line2' in value) || value['line2'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    return true;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json == null) {
        return json;
    }
    return {
        
        'country': json['country'],
        'city': json['city'],
        'line1': json['line1'],
        'line2': json['line2'],
        'state': json['state'],
        'postalCode': json['postal_code'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'country': value['country'],
        'city': value['city'],
        'line1': value['line1'],
        'line2': value['line2'],
        'state': value['state'],
        'postal_code': value['postalCode'],
    };
}

