/* tslint:disable */
/* eslint-disable */
/**
 * ComplianceClerk API
 * ComplianceClerk API Specs for authenticated users.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CardChecks } from './CardChecks';
import {
    CardChecksFromJSON,
    CardChecksFromJSONTyped,
    CardChecksToJSON,
} from './CardChecks';
import type { CardWallet } from './CardWallet';
import {
    CardWalletFromJSON,
    CardWalletFromJSONTyped,
    CardWalletToJSON,
} from './CardWallet';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { TypeEnum } from './TypeEnum';
import {
    TypeEnumFromJSON,
    TypeEnumFromJSONTyped,
    TypeEnumToJSON,
} from './TypeEnum';
import type { CardNetworks } from './CardNetworks';
import {
    CardNetworksFromJSON,
    CardNetworksFromJSONTyped,
    CardNetworksToJSON,
} from './CardNetworks';

/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly id: string;
    /**
     * The type of the PaymentMethod.
     * 
     * * `acss_debit` - ACSS Dbit
     * * `affirm` - Affirm
     * * `afterpay_clearpay` - Afterpay Clearpay
     * * `alipay` - Alipay
     * * `au_becs_debit` - BECS Debit (Australia)
     * * `bacs_debit` - Bacs Direct Debit
     * * `bancontact` - Bancontact
     * * `blik` - BLIK
     * * `boleto` - Boleto
     * * `card` - Card
     * * `card_present` - Card present
     * * `cashapp` - Cash App
     * * `customer_balance` - Customer Balance
     * * `eps` - EPS
     * * `fpx` - FPX
     * * `giropay` - Giropay
     * * `grabpay` - Grabpay
     * * `ideal` - iDEAL
     * * `interac_present` - Interac (card present)
     * * `klarna` - Klarna
     * * `konbini` - Konbini
     * * `link` - Link
     * * `oxxo` - OXXO
     * * `p24` - Przelewy24
     * * `paynow` - PayNow
     * * `paypal` - PayPal
     * * `pix` - Pix
     * * `promptpay` - PromptPay
     * * `sepa_debit` - SEPA Direct Debit
     * * `sofort` - SOFORT
     * * `us_bank_account` - ACH Direct Debit
     * * `wechat_pay` - Wechat Pay
     * * `zip` - Zip
     * @type {TypeEnum}
     * @memberof PaymentMethod
     */
    type: TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly displayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    readonly isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly brand: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly displayBrand: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly last4: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    readonly expMonth: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    readonly expYear: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly funding: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly country: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly fingerprint: string;
    /**
     * 
     * @type {CardChecks}
     * @memberof PaymentMethod
     */
    readonly checks: CardChecks;
    /**
     * 
     * @type {CardWallet}
     * @memberof PaymentMethod
     */
    readonly wallet: CardWallet;
    /**
     * 
     * @type {CardNetworks}
     * @memberof PaymentMethod
     */
    readonly networks: CardNetworks;
    /**
     * 
     * @type {Address}
     * @memberof PaymentMethod
     */
    readonly billingAddress: Address;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly billingEmail: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly billingPhone: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly billingName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly billingDisplayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    readonly isWallet: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly walletType: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethod
     */
    readonly expiryDisplay: string;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethod
     */
    readonly threeDSecureSupported: boolean;
}



/**
 * Check if a given object implements the PaymentMethod interface.
 */
export function instanceOfPaymentMethod(value: object): value is PaymentMethod {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('displayName' in value) || value['displayName'] === undefined) return false;
    if (!('isDefault' in value) || value['isDefault'] === undefined) return false;
    if (!('brand' in value) || value['brand'] === undefined) return false;
    if (!('displayBrand' in value) || value['displayBrand'] === undefined) return false;
    if (!('last4' in value) || value['last4'] === undefined) return false;
    if (!('expMonth' in value) || value['expMonth'] === undefined) return false;
    if (!('expYear' in value) || value['expYear'] === undefined) return false;
    if (!('funding' in value) || value['funding'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('fingerprint' in value) || value['fingerprint'] === undefined) return false;
    if (!('checks' in value) || value['checks'] === undefined) return false;
    if (!('wallet' in value) || value['wallet'] === undefined) return false;
    if (!('networks' in value) || value['networks'] === undefined) return false;
    if (!('billingAddress' in value) || value['billingAddress'] === undefined) return false;
    if (!('billingEmail' in value) || value['billingEmail'] === undefined) return false;
    if (!('billingPhone' in value) || value['billingPhone'] === undefined) return false;
    if (!('billingName' in value) || value['billingName'] === undefined) return false;
    if (!('billingDisplayName' in value) || value['billingDisplayName'] === undefined) return false;
    if (!('isWallet' in value) || value['isWallet'] === undefined) return false;
    if (!('walletType' in value) || value['walletType'] === undefined) return false;
    if (!('expiryDisplay' in value) || value['expiryDisplay'] === undefined) return false;
    if (!('threeDSecureSupported' in value) || value['threeDSecureSupported'] === undefined) return false;
    return true;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': TypeEnumFromJSON(json['type']),
        'displayName': json['display_name'],
        'isDefault': json['is_default'],
        'brand': json['brand'],
        'displayBrand': json['display_brand'],
        'last4': json['last4'],
        'expMonth': json['exp_month'],
        'expYear': json['exp_year'],
        'funding': json['funding'],
        'country': json['country'],
        'fingerprint': json['fingerprint'],
        'checks': CardChecksFromJSON(json['checks']),
        'wallet': CardWalletFromJSON(json['wallet']),
        'networks': CardNetworksFromJSON(json['networks']),
        'billingAddress': AddressFromJSON(json['billing_address']),
        'billingEmail': json['billing_email'],
        'billingPhone': json['billing_phone'],
        'billingName': json['billing_name'],
        'billingDisplayName': json['billing_display_name'],
        'isWallet': json['is_wallet'],
        'walletType': json['wallet_type'],
        'expiryDisplay': json['expiry_display'],
        'threeDSecureSupported': json['three_d_secure_supported'],
    };
}

export function PaymentMethodToJSON(value?: Omit<PaymentMethod, 'id'|'display_name'|'is_default'|'brand'|'display_brand'|'last4'|'exp_month'|'exp_year'|'funding'|'country'|'fingerprint'|'checks'|'wallet'|'networks'|'billing_address'|'billing_email'|'billing_phone'|'billing_name'|'billing_display_name'|'is_wallet'|'wallet_type'|'expiry_display'|'three_d_secure_supported'> | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'type': TypeEnumToJSON(value['type']),
    };
}

